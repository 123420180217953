/*
 * @Description: 图片
 * @Author: 琢磨先生
 * @Date: 2022-06-15 12:35:21
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-15 16:37:19
 */

export default {
  /**
   * 图片压缩
   * @param {*} file
   */
  compress(file) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        var img = new Image();
        img.src = e.currentTarget.result;
        console.log(e);
        img.onload = () => {
          var w = img.width,
            h = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          canvas.setAttribute("width", w);
          canvas.setAttribute("height", h);
          ctx.drawImage(img, 0, 0, w, h);
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75
          );
        };
      };
    });
  },
};
